ul {
    list-style: none;
    margin: 0rem 2rem;
  }

  ul li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #d0202d; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
  }

  ul li {
      margin: 10px 0px;
  }