.resumeBg {
    background-image: url('../../assets/svg/resumeBackground.svg');
    background-repeat: no-repeat;
    background-attachment: inherit;
    background-position: left 100px top 0px;
    background-origin: content-box;
    background-size: 100% 100%;
    height: 100vh;
  }
  
  .contactUs{
    background-image: url('../../assets/svg/tree.svg');
    background-repeat: no-repeat;
    background-attachment: inherit;
    background-position: left 50px top 0px;
    background-origin: content-box;
    background-size: 200% 100%;
  }
  
  .placements {
    background-image: url('../../assets/svg/path.svg');
    background-repeat: no-repeat;
    background-attachment: inherit;
    background-position: left 0px bottom 0px;
    background-origin: content-box;
    background-size: 100% 100%;
  }
  
  .about{
    background-image: url('../../assets/svg/cabin.svg');
    background-repeat: no-repeat;
    background-attachment: inherit;
    background-position: left 150px top 0px;
    background-origin: content-box;
    background-size: 100% 100%;
  }

  .course{
    background-image: url('../../assets/svg/leafPage.svg');
    background-repeat: no-repeat;
    background-attachment: inherit;
    background-position: left 120px top 10px;
    background-origin: content-box;
    background-size: 100% 100%;
  }

  .faq{
    background-image: url('../../assets/svg/faq.svg');
    background-repeat: no-repeat;
    background-attachment: inherit;
    background-position: left 150px top 0px;
    background-origin: content-box;
    background-size: 100% 100%;
  }

.arrowDown {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
   }

.scrollSnap{
    height: 100vh;
    width: 100vw;
    scroll-snap-type: y mandatory;
    overflow: auto;
  }
  
  .scrollSnap > section {
    scroll-snap-align: center;
    width: 100vw;
    height: 100vh;
  }

.contentOpacity {
  opacity: var(--opacityFactor);
}

@media (min-width: 1024px) {
    .resumeBg {
      background-position: left 400px top 100px;
      background-size: 100% 80%;
    }
    .contactUs {
      background-position: left 400px top 100px;
      background-size: 100% 80%;
    }
    .placements {
      background-position: left 0px top 0px;
      background-size: 100% 100%;
    }
  
    .about {
      background-position: left 400px top 20px;
      background-size: 70% 90%;
    }

    .course {
      background-position: left 400px top 20px;
      background-size: 100% 90%;
    }

    .faq {
      background-position: left 400px top 20px;
      background-size: 100% 90%;
    }
  }